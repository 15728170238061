<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="고객사 정보"></CarrotTitle>
        <div class="board">
            <div class="mt-40"> 
                <div class="tabDefult-col">
                    <router-link :to="{ name:'customerManagement-customerDBViewTab1-idx', params:{ idx:view.idx } }"><button class="tab-default">정보</button></router-link>
                    <router-link :to="{ name:'customerManagement-customerDBViewTab2-idx', params:{ idx:view.idx } }"><button class="tab-default on">고객사일지</button></router-link>
                    <router-link :to="{ name:'customerManagement-customerDBViewTab3-idx', params:{ idx:view.idx } }"><button class="tab-default">명함</button></router-link>
                </div>
                <div>
                    <table class="table-col">
                        <colgroup>
                            <col width="60">
                            <col width="100">
                            <col width="90">
                            <col width="90">
                            <col width="90">
                            <col width="90">
                            <col width="*">
                            <col width="180">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>방문일시</th>
                                <th>업체담당자</th>
                                <th>수업지속성</th>
                                <th>관계지속성</th>
                                <th>외근목적</th>
                                <th>클래스</th>
                                <th>HQ매니저</th>
                                <th>작성일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in view.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td><router-link :to="{ name:'customerManagement-outsideWorkView-idx', params:{ idx:irow.idx } }" target="_blank"><span class="btn-view">{{ irow.vdate }}</span></router-link></td>
                                <td>{{ irow.kname }}</td>
                                <td>
                                    <template v-for="(jrow, j) in view.cp_list" :key="j">
                                        <img v-if="irow.class_persistence==jrow.code" :src="require('@/assets/img/db_relation_0' + (j+1) + '.png')" :alt="jrow.name">
                                        <span v-if="j == 0">-</span>
                                    </template>
                                </td>
                                <td>
                                    <template v-for="(jrow, j) in view.rp_list" :key="j">
                                        <img v-if="irow.relation_persistence==jrow.code" :src="require('@/assets/img/db_durability_0' + (j+1) + '.png')" :alt="jrow.name">
                                        <span v-if="j == 0">-</span>
                                    </template>
                                </td>
                                <td>{{ irow.rtype }}</td>
                                <td>{{ irow.out_prc_class }}</td>
                                <td>{{ irow.staff_ename }}({{ irow.staff_kname }})</td>
                                <td>{{ irow.wdate }}</td>
                            </tr>
                            <tr v-if="view.total==0">
                                <td colspan="9">조회중입니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="view.total" :list_per_page="view.rows" v-model="view.page" @change="view.doSearch"></CarrotPaging>
                    
                    <button class="btn-default float-left mt-30" @click="view.goList">목록</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'


export default {
    layout:"customerManagement",
    components: {
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const view = reactive({
            cp_list : [],
            rp_list : [],
            rt_list : [],

            idx : 0,
            info : {},

            page : 1, 
            rows : 10,
            list : {}, total : 0,

            goList : () => {
                router.push({
                    name : "customerManagement-customerDBList"
                });
            },

            doSearch : () => {
                if( view.idx == 0 ) return;

                let params = {
                    page : view.page,
                    rows : view.rows,
                    idx  : view.idx
                };

                axios.get("/rest/customermgr/getCustomerSchedules", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.total = res.data.total;
                        view.list  = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearchInfo : () => {
                let params = {
                    idx : view.idx
                };

                axios.get("/rest/customermgr/getCustomerDBInfo", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        view.info = res.data;

                        view.doSearch();
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearchCP : () => {
                axios.get("/rest/customermgr/getCPtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        view.cp_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSearchRP : () => {
                axios.get("/rest/customermgr/getRPtypeList", { params:{} }).then((res) => {
                    if( res.data.err == 0 ){
                        view.rp_list = res.data.list;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },
        });

        onMounted(() => {
            // Mounted
            view.idx = route.params.idx?route.params.idx:0;

            if ( !view.idx ) {
                router.back(-1);
            }

            view.doSearchCP();
            view.doSearchRP();
            view.doSearchInfo();
        });

        return {view};
    }
}
</script>

<style lang="scss" scoped>
</style>